import { jumpTo } from '@/lib/redirect'
export enum Env {
  PROD = 'PROD',
  STAGE = 'STAGE',
  TEST = 'TEST',
  DEV = 'DEV',
  LOCAL = 'LOCAL'
}
export const HOST_MAP: {
  [key in Env]: string;
} & { [key: string]: string } = {
  [Env.PROD]: 'https://prod.pandateacher.com',
  [Env.STAGE]: 'https://stage.pandateacher.com',
  [Env.TEST]: 'https://test.pandateacher.com',
  [Env.DEV]: 'https://dev.pandateacher.com',
  [Env.LOCAL]: 'http://localhost'
};

export const container = '#qiankun-container';

export const RouteConfig = [
  { name: 'app', port: 8086, props: { jumpTo } },
  { name: 'core', port: 8087 },
  { name: 'enrolment', port: 8088, props: { jumpTo }}
];
