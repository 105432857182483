export function urlJoin(...strArray: string[]) {
  const resultArray = [];
  if (strArray.length === 0) {
    return '';
  }

  if (strArray[0].match(/^[^/:]+:\/*$/) && strArray.length > 1) {
    const first = strArray.shift();
    strArray[0] = first + strArray[0];
  }

  if (strArray[0].match(/^file:\/\/\//)) {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1:///');
  } else {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1://');
  }

  for (let i = 0; i < strArray.length; i++) {
    let component = strArray[i];

    if (typeof component !== 'string') {
      throw new TypeError('Url must be a string. Received ' + component);
    }

    if (component === '' && i !== strArray.length - 1) {
      continue;
    }

    if (i > 0) {
      component = component.replace(/^[\/]+/, '');
    }
    if (i < strArray.length - 1) {
      component = component.replace(/[\/]+$/, '');
    } else {
      component = component.replace(/[\/]+$/, '/');
    }

    resultArray.push(component);
  }

  let str = resultArray.join('/');

  str = str.replace(/\/(\?|&|#[^!])/g, '$1');

  const parts = str.split('?');
  str = parts.shift() + (parts.length > 0 ? '?' : '') + parts.join('&');

  return str;
}

export const resolveParams = (): Record<string, any> => {
  try {
    const result: Record<string, any> = {};
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const tailingSlashRE = /\/$/;
export const addTailingSlash = (path: string) => {
  if (tailingSlashRE.test(path)) return path;
  return path + '/';
};

const localReg = /^(local)|\.(svc|local)$/;
export const isLocalHost = localReg.test(window.location.host);

export function setCookie(name: string, value: string) {
  const cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  document.cookie = cookieText;
}
