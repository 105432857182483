import * as Sentry from '@sentry/vue';

export function getRelease(): string {
  const paths = window.location.pathname.split('/');
  const appName = paths.pop();
  return `${appName}-${window.ENV}@${(window as any).COMMIT_VERSION || ''}`;
}
Sentry.init({
  dsn: 'https://ae26ceb6ee2747d596918b31e7f8977d@sentry.forchange.cn/10',
  release: getRelease(),
  environment: window.ENV || 'DEV',
  beforeSend(event: any) {
    Sentry.setTag('url', location.href);
    return event;
  },
  tracesSampleRate: 1.0
});
