import { addTailingSlash, isLocalHost, urlJoin } from './lib/utils';
import { ROUTE_NAMESPACE, ENV as APP_ENV } from './env';
import { HOST_MAP, Env, container } from './config';
import { RouteOption, Config, RegistrableAppConfig } from './types';
import { resolveParams } from '@/lib/utils';
import { prefetchApps } from 'qiankun';
const RouteMap: Record<string, RegistrableAppConfig> = {};
const resolveStableRoute = (option: RouteOption) => {
  const env = option.env;
  if (env === Env.LOCAL) {
    return `${HOST_MAP[env]}:${option.port}`;
  }
  return urlJoin(HOST_MAP[env], ROUTE_NAMESPACE, option.name);
};
const resolveUnstableRoute = (
  route: RouteOption,
  config: Record<string, string | undefined> = {}
) => {
  const configEntry = config?.[`${route.name}.entry`];
  if (configEntry) return configEntry;
  const env = (config[`${route.name}.env`] || config['env'] || APP_ENV || '').toUpperCase();
  const defaultRouteEnv = env === Env.LOCAL ? (isLocalHost ? Env.LOCAL : Env.DEV) : env;
  const url = resolveStableRoute({ ...route, env: defaultRouteEnv });
  return url;
};
const resolveEntry = (config: Config, params: Record<string, any> = {}) => {
  const route = { env: APP_ENV, name: config.name, port: config.port };
  if (APP_ENV === Env.PROD) {
    return resolveStableRoute(route);
  }
  return resolveUnstableRoute(route, params);
};
export const prefetch = (name: string) => {
  if (typeof name !== 'string') {
    if (process.env.ENV !== 'PROD') {
      console.error(`prefetch 应该传入一个 app name，收到的类型为 ${typeof name}`);
    }
    return;
  }
  const app = RouteMap[name];
  if (!app) {
    if (process.env.ENV !== 'PROD') {
      console.error(`尚未在主应用注册 ${name} 子应用`);
    }
    return;
  }
  prefetchApps([app]);
};

export const createRoute = (config: Config) => {
  const params = resolveParams();
  const entry = addTailingSlash(config.entry || resolveEntry(config, params));
  const MatchReg = new RegExp(`${config.name}\\/?$`);
  const defaultActiveRule = (location: Location) => MatchReg.test(location.pathname);
  const route: RegistrableAppConfig = {
    name: config.name,
    entry,
    container,
    activeRule: config.activeRule || defaultActiveRule,
    props: { prefetch, ...config.props }
  };
  RouteMap[route.name] = route;
  return route;
};
