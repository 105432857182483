import path from 'path';
interface Config {
  appName: string;
  route?: string;
}

const appSystemRoutes = [
  '/ability-test',
  '/about',
  '/app-center',
  '/apps',
  '/env-test',
  '/feedback',
  '/helpcenter',
  '/manual',
  '/privacy',
  '/propagation-model-center',
  '/protocol',
  '/apps/my-reward',
  '/rules',
  '/static-page',
  '/study-card',
  '/tentative',
  '/train-reservation',
  '/question'
]; // const appSystemRoutes = [
//   '/ability-test/:appId',
//   '/ability-test/:appId/test',
//   '/ability-test/:appId/result',
//   '/about',
//   '/app-center',
//   '/apps/:app_id/home',
//   '/apps/:app_id/extra-practice/:id',
//   '/env-test',
//   '/feedback',
//   '/feedback/edit-dislike',
//   '/feedback/dislike',
//   '/feedback/edit-like',
//   '/feedback/like',
//   '/helpcenter/:id',
//   '/',
//   '/manual',
//   '/privacy',
//   '/propagation-model-center',
//   '/protocol',
//   '/apps/my-reward',
//   '/rules/:ruleId',
//   '/static-page',
//   '/study-card',
//   '/tentative/:plan_id/:page_id',
//   '/train-reservation',
//   '/question'
// ];
const coreSystemRoutes = ['/practices', '/lecture'];
// const coreSystemRoutes = ['/apps/:appId/practices/:id', '/apps/:appId/lecture/:id'];
const enrolmentSystemRoutes = [
  '/enrolment-trial',
  '/trial-login',
  '/enrolment',
  '/upgrade-activity',
  '/introduce',
  '/analysis'
];
// const enrolmentSystemRoutes = [
//     '/enrolment-trial/:id',
//     '/trial-login',
//     '/enrolment/:id(\\d+)',
//     '/upgrade-activity',
//     '/introduce',
//     '/analysis/:id/data-analysis'
//   ];

const resolvePath = (name: string) => {
  const pathname = window.location.pathname;
  return path.resolve(pathname, '..', name);
};
export const redirect = (config: Config) => {
  const appRoute = config.route || '/';
  
  // locationJump 本系统自身使用window.location.href跳转
  const locationJump = window.location.pathname.indexOf(config.appName) > -1;
  if (!locationJump) {
    history.pushState(null, '', `${config.appName}${window.location.search || ''}#${appRoute}`);
    return;
  }
  window.location.href = `${resolvePath(config.appName)}${window.location.search || ''}#${appRoute}`;
};

const judgeCoreRoute = (url: string) => {
  return coreSystemRoutes.findIndex((v) => url.indexOf(v) > -1) > -1;
};
const judgeEnrolmentRoute = (url: string) => {
  return enrolmentSystemRoutes.findIndex((v) => url.indexOf(v) > -1) > -1;
};
const judgeAppRoute = (url: string) => {
  return appSystemRoutes.findIndex((v) => url.indexOf(v) > -1) > -1;
};

const absolutePath = (url: string, target?: string) => {
  const urlArr = url.split('#');
  let path = '';
  if (!urlArr[1]) {
    path = url;
  } else if (judgeCoreRoute(`#${urlArr[1]}`)) {
    path = urlArr.join('core#');
  } else if (judgeEnrolmentRoute(`#${urlArr[1]}`)) {
    path = urlArr.join('enrolment#');
  } else if (judgeAppRoute(`#${urlArr[1]}`)) {
    path = urlArr.join('app#');
  } else {
    path = url;
  }
  if (target === '_self') {
    window.location.href = path;
  } else {
    window.open(path);
  }
};

const relativePath = (url: string, target?: string) => {
  if (target === '_self') {
    if (judgeCoreRoute(url)) {
      redirect({ appName: 'core', route: url });
    } else if (judgeEnrolmentRoute(url)) {
      redirect({ appName: 'enrolment', route: url });
    } else {
      redirect({ appName: 'app', route: url });
    }
  } else {
    let path = '';
    if (judgeCoreRoute(url)) {
      path = `core${window.location.search || ''}#${url}`;
    } else if (judgeEnrolmentRoute(url)) {
      path = `enrolment${window.location.search || ''}#${url}`;
    } else {
      path = `app${window.location.search || ''}#${url}`;
    }
    window.open(path);
  }
};
/**
 * 为了兼容老版本的跳转，前端通过返回地址匹配路由，增加子应用前缀进行跳转
 * @param url 配置接口返回地址
 * @param target 跳转方式
 */
export const jumpTo = (url: string, target?: string) => {
  if (/https?/.test(url)) {
    absolutePath(url, target);
  } else if (/^\//.test(url)) {
    relativePath(url, target);
  }
};
