import { registerMicroApps, start, setDefaultMountApp, Entry } from 'qiankun';
import { createRoute } from './route';
import { RouteConfig } from '@/config';
import qs from 'query-string';
import './actions';
import bootstrap from './bootstrap';
import './sentry';
const search = window.location.search;

const cusFetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response> = (
  ...arg
) => {
  const queryParams = qs.parse(search);
  const [rawUrl, ...other] = arg;
  let targetUrl = rawUrl;
  if (queryParams?.version) {
    targetUrl += `?version=${queryParams.version}`;
  }
  return window.fetch(targetUrl, ...other);
};

const getPublicPath = (entry: Entry) => {
  let appName = '';
  if (typeof entry === 'string' && window.ENV === 'PROD') {
    appName = entry.replace(/.*\/web-fos\/(?=\w{1,})/, function () {
      return '';
    });
    return `//acdnpy.pandateacher.com/web-fos-${appName}`;
  }

  if (typeof entry === 'string') {
    const { origin, pathname } = new URL(entry, location.href);
    const paths = pathname.split('/');
    paths.pop();
    return `${origin}${paths.join('/')}/`;
  }

  return '/';
};

registerMicroApps(RouteConfig.map(createRoute));

bootstrap();

// first pick
start({ prefetch: ['app'], singular: true, fetch: cusFetch, getPublicPath });

// 相对 entry 路径
setDefaultMountApp('app');
