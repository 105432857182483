import { initGlobalState, MicroAppStateActions } from 'qiankun';

const initialState: any = { enrolment: {}, root: {} };
const actions: MicroAppStateActions = initGlobalState(initialState);
actions.onGlobalStateChange((newState: any, prev: any) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log('main change', JSON.stringify(newState), JSON.stringify(prev))
});

actions.offGlobalStateChange();

export default actions;
